/* eslint-disable global-require */

/* eslint-disable @typescript-eslint/no-var-requires */

import { AmplitudeClient } from 'amplitude-js';
import MobileDetect from 'mobile-detect';

import { LogEventArgs } from '@/hooks/analytics/types';
import CalmCookie from '@/utils/CalmCookie';

import { calmLogger } from '../calmLogger';

declare global {
	interface Window {
		/**
		 * This is named `amplitude` instead of `amplitudeInstance` (like it is in other repos https://github.com/search?q=org%3Acalm+amplitudeInstance&type=code)
		 * because the 'Appcues` service we are using has an default integration with Amplitude that expects the amplitude SDK to be
		 * globally at `window.amplitude`.
		 */
		amplitude: AmplitudeClient;
	}
}

const MAX_RETRIES = 10;
const RETRY_DELAY = 200;

function isAnalyticsReady(): boolean {
	return Boolean(window?.amplitude?.getSessionId());
}

export function setAmplitudeUserId(
	userId: Parameters<typeof window.amplitude.setUserId>[0],
	userRole: string | undefined,
	retries = 0,
): void {
	if (!isAnalyticsReady() && retries <= MAX_RETRIES) {
		setTimeout(() => {
			setAmplitudeUserId(userId, userRole, retries + 1);
		}, RETRY_DELAY);
	} else {
		window.amplitude.setUserId(userId);
		if (userId) {
			// For Partner Portal, we only ever set user id as the user's email address
			window.amplitude.setUserProperties({
				email: userId,
				user_role: userRole,
			});
		}
	}
}

export function initAmplitude(): void {
	if (!process.env.NEXT_PUBLIC_AMPLITUDE_TOKEN) {
		calmLogger.warn('Error in initAmplitude: NEXT_PUBLIC_AMPLITUDE_TOKEN not defined');
		return;
	}

	// Need to wait to import Amplitude until the client loads
	const Amplitude = require('amplitude-js');

	window.amplitude = Amplitude.getInstance();
	const deviceId = CalmCookie.get('x-device-id');

	window.amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_TOKEN, undefined, {
		includeReferrer: true,
		includeUtm: true,
		platform: 'Partner Portal',
		saveEvents: true,
		batchEvents: true,
		eventUploadPeriodMillis: 1000,
		eventUploadThreshold: 3,
		secureCookie: true,
	});

	window.amplitude.setDomain('calm.com');
	if (deviceId) {
		window.amplitude.setDeviceId(deviceId);
	}

	// set whether mobile
	const md = new MobileDetect(window.navigator.userAgent);
	const deviceType = md.mobile() ? 'mobile' : 'desktop';
	window.amplitude.setUserProperties({ web_device_type: deviceType });
	window.amplitude.setUserProperties({ Other: deviceId });
}

export async function logAmplitudeEvent({
	eventName,
	eventProps,
	retries = 0,
}: LogEventArgs): Promise<string | void | undefined> {
	if (!isAnalyticsReady() && retries <= MAX_RETRIES) {
		setTimeout(() => {
			logAmplitudeEvent({ eventName, eventProps, retries: retries + 1 }).catch(err =>
				calmLogger.error('Error in logAmplitudeEvent', {}, err),
			);
		}, RETRY_DELAY);
	} else {
		return new Promise(resolve => {
			window.amplitude.logEvent(eventName, eventProps, () => {
				resolve('succeed');
			});
		});
	}

	return undefined;
}
