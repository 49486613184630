import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { ErrorBoundary as LibErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import { fontSize, FontSizes, units } from '@calm-web/design-system';

import { calmLogger } from '@/utils/calmLogger';

const StyledErrorDisplay = styled.div`
	padding: ${units(3)};
	margin: auto;
	${fontSize(FontSizes.lg)}

	div + div {
		margin-top: ${units(2)};
	}
`;

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
}

const ErrorDisplay: FC<Props> = ({ error }) => {
	useEffect(() => {
		calmLogger.error('Uncaught error bubbled all the way to the ErrorBoundary', {}, error);
	}, [error]);

	return (
		<StyledErrorDisplay>
			<div>An unexpected error occurred. It has been reported to our team and we &apos;ll investigate.</div>
			<div>Sorry about that! Please try again later.</div>
			<div>If the error persists, please contact your account manager.</div>
		</StyledErrorDisplay>
	);
};

export default function ErrorBoundary({ children }: { children: ReactNode }): ReactElement {
	return <LibErrorBoundary FallbackComponent={ErrorDisplay}>{children}</LibErrorBoundary>;
}
